import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { companyRequest } from '../../actions'

import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import { toggleFilterTag, toggleFilter } from '../00-utils/u-helper'

import ATextPadded from '../01-atoms/a-text-padded'
import AInputSearchStore from '../01-atoms/a-input-search-store'

import MChipContainer from '../02-molecules/m-chip-container'
import MFilterChip from '../02-molecules/m-filter-chip'
import MFilterChipTypo from '../02-molecules/m-filter-chip-typo'

import { globalNaming } from '../../../site-config'

const styles = theme => ({
  containerOuter: {
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    transition: 'opacity .3s ease-in',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.typography.pxToRem(57),
    },
  },
  containerInner: {
    position: 'relative',
    zIndex: 1,
    maxWidth: 950,
    width: '100%',
    margin: 'auto',
    padding: '135px 10px 110px 10px',
    textAlign: 'center',
  },
  subline: {
    marginBottom: theme.typography.pxToRem(50),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.25,
    fontWeight: 600,
    color: theme.customStyles.extraColors.introColor,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(22),
    },
  },
})

class OIntro extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      categories,
      classes,
      companyOverviewFilter,
      companyRequest,
      img,
      headline,
      subline,
      tags,
    } = this.props

    return (
      <header className={classes.containerOuter}>
        <div className={classes.containerInner}>
          <ATextPadded>{headline}</ATextPadded>

          <Typography className={classes.subline}>{subline}</Typography>

          <AInputSearchStore
            size="large"
            placeholder={globalNaming.searchPlaceholder}
          />

          <MChipContainer
            title="Kategorie wählen"
            divider={true}
            style={{ opacity: categories.length ? 1 : 0 }}
          >
            {categories
              .sort((a, b) => {
                return a.name - b.name
              })
              .map(category => (
                <MFilterChipTypo
                  label={category.name}
                  key={category.id}
                  size="large"
                  invert
                  onClick={() =>
                    toggleFilter(
                      'category',
                      category.id,
                      companyOverviewFilter,
                      companyRequest
                    )
                  }
                />
              ))}
          </MChipContainer>

          <MChipContainer
            title="beliebte Tags"
            type="tags"
            style={{ opacity: tags.length ? 1 : 0 }}
          >
            {tags
              .sort((a, b) => {
                return b.count - a.count
              })
              .slice(0, 10)
              .map(tag => (
                <MFilterChip
                  label={tag.name}
                  key={tag.id}
                  onClick={() =>
                    toggleFilterTag(
                      tag.id,
                      companyOverviewFilter,
                      companyRequest
                    )
                  }
                />
              ))}
          </MChipContainer>
        </div>

        {img}
      </header>
    )
  }
}

OIntro.propTypes = {
  categories: PropTypes.array,
  classes: PropTypes.object.isRequired,
  img: PropTypes.element,
  headline: PropTypes.string,
  searchString: PropTypes.string,
  subline: PropTypes.string,
  tags: PropTypes.array,
  companyOverviewFilter: PropTypes.object,
  companyRequest: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    categories:
      typeof state.apiResults == 'object' &&
      typeof state.apiResults.category == 'object'
        ? state.apiResults.category.raw
        : [],
    tags:
      typeof state.apiResults == 'object' &&
      typeof state.apiResults.tag == 'object'
        ? state.apiResults.tag.raw
        : [],
    companyOverviewFilter: state.companyOverviewFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    companyRequest: apiData => dispatch(companyRequest(apiData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OIntro))
