import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Pagination from 'react-js-pagination'

const styles = theme => ({
  paginationWrapper: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(40),
    '& ul': {
      listStyleType: 'none',
    },
    '& li': {
      display: 'inline-block',
      padding: theme.typography.pxToRem(10),
      '& a': {
        textDecoration: 'none',
        color: theme.customStyles.extraColors.lightGrey,
        fontFamily: 'Roboto',
      },
      '&.active': {
        '& a': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
})

/** APagination Component */
class APagination extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(pageNumber) {
    if (typeof this.props.handlePageChange === 'function') {
      this.props.handlePageChange(pageNumber)
    }
  }

  render() {
    const { totalcount, currentpage, classes } = this.props,
      // If you change number for itemsPerPage it must be changed in REST API as well
      itemsPerPage = 30,
      showPagination = totalcount / itemsPerPage > 1

    return showPagination ? (
      <div className={classes.paginationWrapper}>
        <Pagination
          activePage={currentpage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalcount}
          pageRangeDisplayed={3}
          onChange={this.handleChange}
        />
      </div>
    ) : (
      false
    )
  }
}

APagination.propTypes = {
  classes: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func,
  totalcount: PropTypes.number,
  currentpage: PropTypes.number,
}

export default withStyles(styles)(APagination)
