import React from 'react'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { companyRequest, searchRequestGet } from '../../actions'
import { isUndefinedOrFunction } from '../00-utils/u-helper'
import AInputSearch from './a-input-search.js'

class AInputSearchStore extends React.Component {
  constructor(props) {
    super(props)

    this.getSearchResult = this.getSearchResult.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleDelete() {
    let filter = {
      ...this.props.apiData.companyOverviewFilter,
      search: false,
      page: false,
    }

    this.props.searchRequestGet(false)
    this.props.companyRequest({ query: 'all', filter: filter })
  }

  getSearchResult(e) {
    if (e.keyCode === 13) {
      e.preventDefault()
      let filter = {
        ...this.props.apiData.companyOverviewFilter,
        search: e.target.value,
        page: false,
      }

      this.props.companyRequest({ query: 'all', filter: filter })
    }
  }

  render() {
    const { placeholder, size, searchString, searchRequestGet } = this.props

    return (
      <AInputSearch
        handleKeyDown={e => this.getSearchResult(e)}
        handleChange={searchRequestGet}
        handleDelete={this.handleDelete}
        value={searchString ? searchString : ''}
        size={size}
        placeholder={placeholder}
      />
    )
  }
}

AInputSearchStore.propTypes = {
  searchString: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  getSearchResult: isUndefinedOrFunction,
  searchRequestGet: isUndefinedOrFunction,
  companyRequest: isUndefinedOrFunction,
  size: PropTypes.string,
  apiData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    apiData: {
      companyOverviewFilter: state.companyOverviewFilter,
    },
    searchString: state.search.string,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    companyRequest: apiData => dispatch(companyRequest(apiData)),
    searchRequestGet: searchString => dispatch(searchRequestGet(searchString)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AInputSearchStore)
