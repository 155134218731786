import React from 'react'

import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

import AInputSearchStore from '../01-atoms/a-input-search-store'
import OFilterSidebar from '../03-organisms/o-filter-sidebar'

const styles = theme => ({
  searchHeadline: {
    color: theme.customStyles.extraColors.lightGrey,
    fontSize: theme.typography.pxToRem(16),
  },
})
class OSidebar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { categories, /*tags, locations,*/ classes } = this.props

    return (
      <React.Fragment>
        <Typography className={classes.searchHeadline} component="div">
          Suche
        </Typography>
        <AInputSearchStore size="small" placeholder="Suchbegriff eingeben" />

        <OFilterSidebar
          headline="Kategorien"
          arrayToFilter={categories}
          type="category"
        />
        {/* <OFilterSidebar headline="Tags" arrayToFilter={tags} displayAsChip />
        <OFilterSidebar
          headline="Länder"
          arrayToFilter={locations.countries}
          type="country"
        />
        <OFilterSidebar
          headline="Städte"
          arrayToFilter={locations.cities}
          type="city"
        /> */}
      </React.Fragment>
    )
  }
}

OSidebar.propTypes = {
  categories: PropTypes.array,
  tags: PropTypes.array,
  locations: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(styles)(OSidebar)
