import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { connect } from 'react-redux'
import { companyRequest } from '../actions'

import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'

import APaper from '../components/01-atoms/a-paper'
import APagination from '../components/01-atoms/a-pagination'
import Aloader from '../components/01-atoms/a-loader'
import MCard from '../components/02-molecules/m-card'
import MFilterActive from '../components/02-molecules/m-filter-active'
import OIntro from '../components/03-organisms/o-intro'
import OSidebar from '../components/03-organisms/o-sidebar'

import { appInfo, globalNaming } from '../../site-config'

const styles = theme => ({
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    fontSize: 28,
  },
  mainContent: {
    ...theme.customStyles.mainContent,
  },
  media: {
    height: 360,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(21),
  },
  results: {
    color: theme.customStyles.extraColors.lightGrey,
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'right',
    marginBottom: theme.typography.pxToRem(8),
  },
  contentContainer: {
    position: 'relative',
  },
  loadingFilterContainer: {
    position: 'absolute',
    zIndex: 99,
    width: '100%',
    height: '100%',
    backgroundColor: theme.customStyles.extraColors.white,
    opacity: '0.5',
    textAlign: 'center',
  },
})

const OIntroWrapper = ({ imgSizes }) => {
  return (
    <OIntro
      img={
        <Img
          fluid={imgSizes}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: '100%',
          }}
        />
      }
      headline={appInfo.introTitle}
      subline={`Finden Sie Business-Partner und ${globalNaming.entryTypeAlternative} für Ihr Unternehmen`}
    />
  )
}
OIntroWrapper.propTypes = {
  imgSizes: PropTypes.object,
}

/** IndexPage used for Home */
class IndexPage extends Component {
  constructor(props) {
    super(props)

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount() {
    // fetch all needed requests via redux
    this.props.companyRequest({ query: 'all' })
  }

  componentDidUpdate() {
    // console.log('componentDidUpdate()', prevProps, this.props)

    // there is an active search, scroll Search Results into view
    if (this.props.search.result) {
      var paperContainer = document.getElementById('paperContainer')
      paperContainer.scrollIntoView()
    }

    if (this.props.apiData.apiResults === false) {
      // console.log('fetch results again, this is a reload')

      // fetch all needed requests via redux when reloading this page
      this.props.companyRequest({ query: 'all' })
    }
  }

  handlePageChange(pageNumber) {
    //The notation below is used to combine two or more objects/vars see: https://stackoverflow.com/a/50729063
    let filter = {
      ...this.props.apiData.companyOverviewFilter,
      page: pageNumber,
    }
    this.props.companyRequest({ query: 'all', filter: filter })
  }

  render() {
    const { classes, data } = this.props
    // if JSON request has not been loaded

    const loadingFilter = this.props.apiData.currentlySending ? (
      <div className={classes.loadingFilterContainer} />
    ) : (
      false
    )

    if (this.props.apiData.apiResults === false) {
      return (
        <Fragment>
          <OIntroWrapper imgSizes={data.headerMainImg.childImageSharp.fluid} />
          <Grid container justify="center">
            <Grid item xs={12} className={classes.mainContent}>
              <APaper>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Aloader />
                  </Grid>
                </Grid>
              </APaper>
            </Grid>
          </Grid>
        </Fragment>
      )
    }

    // if JSON request has been loaded
    if (
      typeof this.props.apiData.apiResults == 'object' &&
      this.props.apiData.apiResults.company.data instanceof Array
    ) {
      const { category } = this.props.apiData.apiResults

      // transform result array to card components
      const company = this.props.apiData.apiResults.company.data

      const companyFilteredBySearch = company

      const cards = companyFilteredBySearch.map((card, index) => {
        const categories =
          typeof card.company_categories == 'number'
            ? category.mapped[card.company_categories].name
            : false

        //if (typeof card.company_info.company == 'string') { Don't check, otherwise pagination breaks, firma should not be added to event if company name is missing
        return (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <MCard
              type={
                card.company_label &&
                typeof card.company_label.value == 'string'
                  ? card.company_label
                  : {}
              }
              headline={card.company_info.company}
              subline={card.company_info.city}
              imagesrc={
                typeof card.company_info.logo == 'object' &&
                card.company_info.logo.src &&
                card.company_label &&
                (card.company_label.value == 0 || card.company_label.value == 1)
                  ? card.company_info.logo.src
                  : ''
              }
              placeholderImg={data.placeholderImg}
              category={categories}
              imagealt={card.company_info.company}
              to={'/firma/' + card.ID}
              // referencescount={
              //   card.company_info && card.company_info.references
              //     ? card.company_info.references.length
              //     : false
              // }
            />
          </Grid>
        )
        //}
      })

      const results = this.props.apiData.apiResults.company.headers[
        'x-wp-total'
      ]
      return (
        <Fragment>
          <OIntroWrapper imgSizes={data.headerMainImg.childImageSharp.fluid} />
          <Grid container justify="center">
            <Grid item xs={12} className={classes.mainContent}>
              <APaper id="paperContainer">
                <Grid
                  container
                  spacing={3}
                  className={classes.contentContainer}
                >
                  {loadingFilter}
                  <MFilterActive />
                  <Grid item xs={12} sm={12} md={3}>
                    <OSidebar
                      categories={this.props.apiData.apiResults.category.raw}
                      tags={this.props.apiData.apiResults.tag.raw}
                      locations={this.props.apiData.apiResults.locations}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Grid item xs={12}>
                      <Typography className={classes.results} component="div">
                        {results} Ergebnis{results > 1 ? 'se' : ''}
                      </Typography>
                    </Grid>
                    <Grid container spacing={3}>
                      {cards}
                      <Grid item xs={12}>
                        <APagination
                          totalpages={
                            this.props.apiData.apiResults.company.headers[
                              'x-wp-totalpages'
                            ]
                          }
                          totalcount={parseInt(results)}
                          handlePageChange={this.handlePageChange}
                          currentpage={
                            this.props.apiData.companyOverviewFilter.page
                              ? this.props.apiData.companyOverviewFilter.page
                              : 1
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </APaper>
            </Grid>
          </Grid>
        </Fragment>
      )
    }

    // if JSON request failed and fallback for other edge cases
    return (
      <Fragment>
        <OIntroWrapper imgSizes={data.headerMainImg.childImageSharp.fluid} />
        <Grid container justify="center">
          <Grid item xs={12} className={classes.mainContent}>
            <APaper>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormattedMessage
                    id="companyOverviewFallback"
                    defaultMessage="Woops, hier ist etwas schief gelaufen"
                  />
                </Grid>
              </Grid>
            </APaper>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

IndexPage.propTypes = {
  apiData: PropTypes.object,
  classes: PropTypes.object.isRequired,
  companyRequest: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  search: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    apiData: {
      apiResults: state.apiResults,
      currentlySending: state.currentlySending,
      companyOverviewFilter: state.companyOverviewFilter,
    },
    search: state.search,
  }
}

const mapDispatchToProps = dispatch => {
  // console.log(loginRequest({ username: 'abc', password: 'def' }))
  return {
    companyRequest: apiData => dispatch(companyRequest(apiData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(IndexPage))

// eslint-disable-next-line
export const query = graphql`
  query GatsbyImageIndexQuery {
    headerMainImg: file(relativePath: { eq: "images/header-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, toFormat: JPG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    placeholderImg: file(relativePath: { eq: "images/placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
