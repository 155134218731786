import React from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from '@material-ui/styles/withStyles'
import { isUndefinedOrFunction } from '../00-utils/u-helper'

import IconSearch from '../../assets/svgs/entypo-magnifying-glass.svg'
import IconClose from '../../assets/svgs/entypo-cross.svg'

const styles = theme => ({
  searchFieldContainer: {
    position: 'relative',
    margin: '60px auto',
    width: '95%',
    [theme.breakpoints.up('sm')]: {
      width: 550,
    },
  },
  searchFieldContainerSmall: {
    margin: '7px auto 23px',
    width: '100%',
  },
  searchField: {
    background: theme.customStyles.extraColors.lineGrey,
    width: '100%',
    padding: '20px 80px 20px 40px',
    borderRadius: 3,
    border: 'none',
    fontSize: theme.typography.pxToRem(16),

    '&:focus': {
      outline: 'none',
    },
  },
  searchFieldSmall: {
    padding: '10px 50px 10px 15px',
  },
  buttonSearch: {
    position: 'absolute',
    top: 0,
    right: 15,
    width: 50,
    height: '100%',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: 'inherit',
    },
    '&:focus': {
      outline: 'none',
    },
    '& svg': {
      width: '100%',
      height: '100%',
      fill: theme.customStyles.extraColors.white,
    },
  },
  buttonSearchSmall: {
    right: 5,
    width: 36,
  },
})

const InputSearch = ({
  className,
  handleChange,
  handleKeyDown,
  placeholder,
  value,
}) => (
  <input
    type="text"
    placeholder={placeholder}
    className={className}
    onChange={handleChange}
    onKeyDown={handleKeyDown}
    value={value}
  />
)

InputSearch.propTypes = {
  className: PropTypes.string,
  handleChange: isUndefinedOrFunction,
  handleKeyDown: isUndefinedOrFunction,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

const InputButton = ({ className, onDelete, value }) => {
  const hasInput = typeof value == 'string' && value.length > 2
  let buttonProps = {
    type: 'submit',
    className: className,
  }

  if (hasInput) {
    buttonProps.onClick = onDelete
  }

  return hasInput && value.length > 2 ? (
    <button {...buttonProps}>
      <IconClose />
    </button>
  ) : (
    <button {...buttonProps}>
      <IconSearch />
    </button>
  )
}

InputButton.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onDelete: isUndefinedOrFunction,
  value: PropTypes.string,
}

const AInputSearch = ({
  classes,
  handleChange,
  handleKeyDown,
  handleDelete,
  placeholder,
  value,
  size,
}) => {
  const isSmall = size == 'small'
  return (
    <div
      className={classnames(classes.searchFieldContainer, {
        [classes.searchFieldContainerSmall]: isSmall,
      })}
    >
      <InputSearch
        className={classnames(classes.searchField, {
          [classes.searchFieldSmall]: isSmall,
        })}
        handleChange={e => handleChange(e.target.value)}
        handleKeyDown={e => handleKeyDown(e)}
        value={value}
        placeholder={placeholder}
      />
      <InputButton
        className={classnames(classes.buttonSearch, {
          [classes.buttonSearchSmall]: isSmall,
        })}
        value={value}
        onDelete={() => handleDelete()}
      />
    </div>
  )
}

AInputSearch.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  handleChange: isUndefinedOrFunction,
  handleKeyDown: isUndefinedOrFunction,
  handleDelete: isUndefinedOrFunction,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
}

export default withStyles(styles)(AInputSearch)
