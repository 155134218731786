import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'
import { Html5Entities } from 'html-entities'
const htmlEntities = new Html5Entities()

import Img from 'gatsby-image'

import AButton from '../01-atoms/a-button'

// import AIconBusiness from '../01-atoms/a-icon-business'
// import AIconPremium from '../01-atoms/a-icon-premium'
// import AIconReferences from '../01-atoms/a-icon-references'

const styles = theme => ({
  card: {
    display: 'flex',
    height: '100%',
  },
  cardMedia: {
    height: '100%',
    flex: '0 0 100px',
    minHeight: 100,
    borderRight: '1px solid rgba(0,0,0,0.1)',
    backgroundSize: 'contain',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 148px',
      minHeight: 148,
    },
  },
  cardHeadline: {
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 600,
    wordBreak: 'break-word',
  },
  cardContent: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(14),
    paddingBottom: 0,
  },
  cardActions: {
    alignSelf: 'flex-end',
    width: '100%',
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
  },
  typeContainer: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
    '& span': {
      color: theme.customStyles.extraColors.lightGrey,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(12),
    },
  },
  label: {
    textTransform: 'uppercase',
  },
  fabIcon: {
    marginTop: -10,
    marginRight: -10,
  },
  actionDetail: {
    marginLeft: 'auto',
    textTransform: 'uppercase',
  },
  details: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
})
class MCard extends React.Component {
  render() {
    const {
      type,
      headline,
      subline,
      imagesrc,
      placeholderImg,
      imagealt,
      category,
      to,
      classes,
      //referencescount,
      ...other
    } = this.props

    const cardImg = imagesrc ? (
      <CardMedia
        className={classes.cardMedia}
        image={imagesrc}
        alt={imagealt}
      />
    ) : (
      <Img
        className={classes.cardMedia}
        alt={imagealt}
        fluid={placeholderImg.childImageSharp.fluid}
      />
    )

    return (
      <Card className={classes.card} {...other}>
        {cardImg}
        <div className={classes.details}>
          <CardContent className={classes.cardContent}>
            {typeof type == 'object' && type.value == 0 ? (
              <div className={classes.typeContainer}>
                <Typography className={classes.label} component="span">
                  {type.label}
                </Typography>
                {/* <AIconReferences count={referencescount} /> */}
                {/* <div className={classes.fabIcon}>
                  <AIconPremium />
                </div> */}
              </div>
            ) : typeof type == 'object' && type.value == 1 ? (
              <div className={classes.typeContainer}>
                <Typography className={classes.label} component="span">
                  {type.label}
                </Typography>
                {/* <AIconReferences
                  count={referencescount}
                  className={classes.iconReference}
                /> */}
                {/* <div className={classes.fabIcon}>

                  <AIconBusiness />
                </div> */}
              </div>
            ) : (
              ''
            )}
            <Typography className={classes.cardHeadline} variant="h2">
              {headline}
            </Typography>
            {category && category.length > 0 && (
              <Typography variant="subtitle1" component="p">
                {htmlEntities.decode(category)}
              </Typography>
            )}
          </CardContent>
          {to && to.length > 0 && (
            <CardActions className={classes.cardActions} disableSpacing={true}>
              {subline && subline.length > 0 && (
                <Typography variant="subtitle1" component="p">
                  {subline}
                </Typography>
              )}
              <AButton
                className={classes.actionDetail}
                size="small"
                color="primary"
                to={to}
              >
                details
              </AButton>
            </CardActions>
          )}
        </div>
      </Card>
    )
  }
}

MCard.defaultProps = {
  headline: 'Please set the headline',
  subheadline: '',
  imagealt: 'Image',
}

MCard.propTypes = {
  classes: PropTypes.object.isRequired,
  headline: PropTypes.string,
  subline: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  imagealt: PropTypes.string,
  imagesrc: PropTypes.string,
  placeholderImg: PropTypes.object,
  to: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.object,
  // referencescount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default withStyles(styles)(MCard)
