import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

import Chip from '@material-ui/core/Chip'

import { isUndefinedOrFunction } from '../00-utils/u-helper'

import AFilterChipCount from '../01-atoms/a-filter-chip-count'
import AFilterChipDeleteIcon from '../01-atoms/a-filter-chip-delete-icon'

import { Html5Entities } from 'html-entities'
const htmlEntities = new Html5Entities()

const styles = theme => ({
  root: {
    height: theme.typography.pxToRem(25),
    backgroundColor: theme.palette.secondary.main,
    marginRight: '.375em',
    marginLeft: '.375em',
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
})

const MFilterChip = ({ classes, label, labelCount, onClick, onDelete }) => {
  let chipProps = {
    classes: {
      root: classes.root,
      label: classes.label,
    },
    label: labelCount
      ? [
          htmlEntities.decode(label),
          <AFilterChipCount count={labelCount} key="123" />,
        ]
      : htmlEntities.decode(label),
  }

  if (typeof onClick == 'function') chipProps.onClick = onClick
  if (typeof onDelete == 'function') {
    chipProps.deleteIcon = <AFilterChipDeleteIcon />
    chipProps.onDelete = onDelete
  }

  return <Chip {...chipProps} component="div" />
}

MFilterChip.propTypes = {
  /** Target of this Link. External links will be opened in a new window. Internal links (starting with /) will use the gatsby-link component. */
  to: PropTypes.string,
  label: PropTypes.string,
  labelCount: PropTypes.number,
  classes: PropTypes.object,
  onClick: isUndefinedOrFunction,
  onDelete: isUndefinedOrFunction,
}

MFilterChip.defaultProps = {
  onClick: undefined,
  onDelete: undefined,
}

// not using withStyles to be able to use props in styles
// see @url https://github.com/mui-org/material-ui/issues/8726#issuecomment-408274180
export default withStyles(styles)(MFilterChip)
