import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/styles/withStyles'

const styles = theme => ({
  count: {
    marginLeft: '.25em',
    color: theme.customStyles.extraColors.lightGrey,
    fontWeight: 400,
    '&:before': { content: '"("' },
    '&:after': { content: '")"' },
  },
})

const AFilterChipCount = ({ classes, count }) => {
  return <span className={classes.count}>{count}</span>
}

AFilterChipCount.propTypes = {
  classes: PropTypes.object,
  count: PropTypes.number.isRequired,
}

export default withStyles(styles)(AFilterChipCount)
