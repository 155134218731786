import React from 'react'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'

import { connect } from 'react-redux'
import { companyRequest } from '../../actions'

import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

import {
  isTagActive,
  toggleFilterTag,
  isActive,
  toggleFilter,
} from '../00-utils/u-helper'

import MFilterChip from '../02-molecules/m-filter-chip'
import MFilterChipTypo from '../02-molecules/m-filter-chip-typo'
import MChipContainer from '../02-molecules/m-chip-container'

const styles = theme => ({
  filterWrapper: {
    marginBottom: theme.typography.pxToRem(40),
  },
  sidebarHeadline: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(19),
    fontWeight: 600,
    marginBottom: theme.typography.pxToRem(5),
  },
  sidebarDivider: {
    marginBottom: theme.typography.pxToRem(14),
  },
})

class OFilterSidebar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { headline, arrayToFilter, displayAsChip, type, classes } = this.props
    //console.log(arrayToFilter)

    const filterElements = arrayToFilter.map((elem, index) => {
      return displayAsChip ? (
        <MFilterChip
          key={index}
          label={elem.name}
          labelCount={elem.count}
          onClick={() =>
            toggleFilterTag(
              elem.id,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          onDelete={
            isTagActive(elem.id, this.props.apiData.companyOverviewFilter)
              ? () =>
                  toggleFilterTag(
                    elem.id,
                    this.props.apiData.companyOverviewFilter,
                    this.props.companyRequest
                  )
              : undefined
          }
        />
      ) : (
        <MFilterChipTypo
          key={index}
          label={elem.name}
          labelCount={elem.count}
          variant="vertical"
          onClick={() =>
            toggleFilter(
              type,
              type === 'category' ? elem.id : elem.name,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          onDelete={
            isActive(type, elem.name, this.props.apiData.companyOverviewFilter)
              ? () =>
                  toggleFilter(
                    type,
                    type === 'category' ? elem.id : elem.name,
                    this.props.apiData.companyOverviewFilter,
                    this.props.companyRequest
                  )
              : undefined
          }
        />
      )
    })

    return (
      <div className={classes.filterWrapper}>
        <Typography
          className={classes.sidebarHeadline}
          variant="subtitle1"
          component="span"
        >
          {headline}
        </Typography>
        <Divider className={classes.sidebarDivider} />
        <MChipContainer
          type={displayAsChip ? 'tags' : 'categories'}
          variant={'vertical'}
        >
          {filterElements}
        </MChipContainer>
      </div>
    )
  }
}

OFilterSidebar.propTypes = {
  headline: PropTypes.string.isRequired,
  arrayToFilter: PropTypes.array.isRequired,
  displayAsChip: PropTypes.bool,
  classes: PropTypes.object,
  companyRequest: PropTypes.func.isRequired,
  type: PropTypes.string,
  apiData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    apiData: {
      currentlySending: state.currentlySending,
      companyOverviewFilter: state.companyOverviewFilter,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    companyRequest: apiData => dispatch(companyRequest(apiData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OFilterSidebar))
