import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { companyRequest } from '../../actions'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'

import { toggleFilterTag, toggleFilter } from '../00-utils/u-helper'

import MFilterChip from '../02-molecules/m-filter-chip'
import MFilterChipTypo from '../02-molecules/m-filter-chip-typo'

const styles = theme => ({
  filterContainer: {
    marginTop: theme.typography.pxToRem(-12),
  },
  chipContainer: {
    '&:not(:last-child):after': {
      content: '"|"',
      display: 'inline-block',
      color: theme.customStyles.extraColors.lightGrey,
      paddingLeft: theme.typography.pxToRem(10),
      paddingRight: theme.typography.pxToRem(10),
    },
  },
})

class MFilterActive extends React.Component {
  render() {
    const { classes, tags, categories } = this.props

    const filteredCategory =
      typeof this.props.apiData.companyOverviewFilter.category == 'number' ? (
        <MFilterChipTypo
          label={
            categories[this.props.apiData.companyOverviewFilter.category].name
          }
          onClick={() =>
            toggleFilter(
              'category',
              this.props.apiData.companyOverviewFilter.category,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          onDelete={() =>
            toggleFilter(
              'category',
              this.props.apiData.companyOverviewFilter.category,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          size="medium"
        />
      ) : (
        ''
      )

    const filteredTags = this.props.apiData.companyOverviewFilter.tags.length
      ? this.props.apiData.companyOverviewFilter.tags.map(tagId => {
          return (
            <MFilterChip
              key={tags[tagId].id}
              label={tags[tagId].name}
              onClick={() =>
                toggleFilterTag(
                  tagId,
                  this.props.apiData.companyOverviewFilter,
                  this.props.companyRequest
                )
              }
              onDelete={() =>
                toggleFilterTag(
                  tagId,
                  this.props.apiData.companyOverviewFilter,
                  this.props.companyRequest
                )
              }
            />
          )
        })
      : ''
    const filteredCity =
      typeof this.props.apiData.companyOverviewFilter.city == 'string' ? (
        <MFilterChipTypo
          label={this.props.apiData.companyOverviewFilter.city}
          onClick={() =>
            toggleFilter(
              'city',
              this.props.apiData.companyOverviewFilter.city,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          onDelete={() =>
            toggleFilter(
              'city',
              this.props.apiData.companyOverviewFilter.city,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          size="medium"
        />
      ) : (
        ''
      )
    const filteredCountry =
      typeof this.props.apiData.companyOverviewFilter.country == 'string' ? (
        <MFilterChipTypo
          label={this.props.apiData.companyOverviewFilter.country}
          onClick={() =>
            toggleFilter(
              'country',
              this.props.apiData.companyOverviewFilter.country,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          onDelete={() =>
            toggleFilter(
              'country',
              this.props.apiData.companyOverviewFilter.country,
              this.props.apiData.companyOverviewFilter,
              this.props.companyRequest
            )
          }
          size="medium"
        />
      ) : (
        ''
      )

    return filteredCategory ||
      filteredTags ||
      filteredCountry ||
      filteredCity ? (
      <Fragment>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={9}>
          <div className={classes.filterContainer}>
            {filteredCategory ? (
              <span className={classes.chipContainer}>{filteredCategory}</span>
            ) : (
              ''
            )}
            {filteredTags ? (
              <span className={classes.chipContainer}>{filteredTags}</span>
            ) : (
              ''
            )}
            {filteredCountry ? (
              <span className={classes.chipContainer}>{filteredCountry}</span>
            ) : (
              ''
            )}
            {filteredCity ? (
              <span className={classes.chipContainer}>{filteredCity}</span>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </Fragment>
    ) : (
      false
    )
  }
}

MFilterActive.propTypes = {
  classes: PropTypes.object,
  tags: PropTypes.object,
  categories: PropTypes.object,
  apiData: PropTypes.object,
  companyRequest: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    tags: state.apiResults.tag.mapped,
    categories: state.apiResults.category.mapped,
    apiData: {
      currentlySending: state.currentlySending,
      companyOverviewFilter: state.companyOverviewFilter,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    companyRequest: apiData => dispatch(companyRequest(apiData)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MFilterActive))
