import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/styles/withStyles'

import Chip from '@material-ui/core/Chip'

import { isUndefinedOrFunction } from '../00-utils/u-helper'

import AFilterChipCount from '../01-atoms/a-filter-chip-count'
import AFilterChipDeleteIcon from '../01-atoms/a-filter-chip-delete-icon'

import { Html5Entities } from 'html-entities'
const htmlEntities = new Html5Entities()

const styles = theme => ({
  root: {
    backgroundColor: 'transparent',
    height: theme.typography.pxToRem(25),
    '&:focus,&:hover': {
      backgroundColor: 'transparent',
      outline: 'none',
      boxShadow: 'none',
      color: theme.customStyles.extraColors.lightGrey,
    },
  },
  label: {
    fontSize: props => {
      let fontSize

      switch (props.size) {
        case 'medium':
          fontSize = 18
          break
        case 'large':
          fontSize = 22
          break
        default:
          fontSize = 16
      }
      return theme.typography.pxToRem(fontSize)
    },
  },
  isBlock: {
    display: 'block',
    '& span': {
      display: 'inline-block',
      paddingTop: theme.typography.pxToRem(1),
      paddingBottom: theme.typography.pxToRem(1),
    },
  },
  isInline: {
    '&:first-child span': {
      marginLeft: theme.typography.pxToRem(-12),
    },
  },
  invert: { color: theme.customStyles.extraColors.introCategories },
})

const MFilterChipTypo = ({
  classes,
  invert,
  label,
  labelCount,
  onClick,
  onDelete,
  variant,
}) => {
  const getRootClass = (classes, invert, variant) => {
    let rootClass = [classes.root]

    if (invert) rootClass.push(classes.invert)

    variant == 'vertical'
      ? rootClass.push(classes.isBlock)
      : rootClass.push(classes.isInline)

    return rootClass.join(' ')
  }

  let chipProps = {
    classes: {
      root: getRootClass(classes, invert, variant),
      label: classes.label,
      deleteIcon: classes.deleteIcon,
    },
    label: labelCount
      ? [
          htmlEntities.decode(label),
          <AFilterChipCount count={labelCount} key="123" />,
        ]
      : htmlEntities.decode(label),
  }

  if (typeof onClick == 'function') chipProps.onClick = onClick
  if (typeof onDelete == 'function') {
    chipProps.deleteIcon = <AFilterChipDeleteIcon />
    chipProps.onDelete = onDelete
  }

  return <Chip {...chipProps} component="div" />
}

MFilterChipTypo.propTypes = {
  /** Target of this Link. External links will be opened in a new window. Internal links (starting with /) will use the gatsby-link component. */
  label: PropTypes.string,
  labelCount: PropTypes.number,
  classes: PropTypes.object,
  invert: PropTypes.bool,
  onClick: isUndefinedOrFunction,
  onDelete: isUndefinedOrFunction,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
}

MFilterChipTypo.defaultProps = {
  onClick: undefined,
  onDelete: undefined,
  size: 'small',
  variant: 'horizontal',
}

// not using withStyles to be able to use props in styles
// see @url https://github.com/mui-org/material-ui/issues/8726#issuecomment-408274180
export default withStyles(styles)(MFilterChipTypo)
