import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'

import { isUndefinedOrFunction } from '../00-utils/u-helper'

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    margin:
      theme.typography.pxToRem(4) +
      ' ' +
      theme.typography.pxToRem(8) +
      ' 0 ' +
      theme.typography.pxToRem(-6),
  },
})

const AFilterDeleteIcon = ({ classes, onClick }) => {
  return <CloseIcon onClick={onClick} className={classes.root} />
}

AFilterDeleteIcon.propTypes = {
  classes: PropTypes.object,
  onClick: isUndefinedOrFunction,
}

export default withStyles(styles)(AFilterDeleteIcon)
