import React from 'react'

import PropTypes from 'prop-types'
import classnames from 'classnames'

import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'

const randomUnique = Math.random()
    .toString(36)
    .slice(2, 5),
  addDivider = (array, classes) => {
    let i = array.length - 1
    do {
      array.splice(
        i,
        0,
        <span key={randomUnique + '-' + i} className={classes}>
          {' | '}
        </span>
      )
    } while (i-- && i > 0)

    return array
  }

const styles = theme => ({
  headline: {
    marginBottom: theme.typography.pxToRem(5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.customStyles.extraColors.introColor,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  chipContainer: {
    marginBottom: theme.typography.pxToRem(24),
  },
  chipContainerTags: {
    lineHeight: 2,
  },
  isVerticalChip: {
    marginLeft: '-.375em',
  },
  isVertical: {
    marginLeft: theme.typography.pxToRem(-12),
  },
  divider: {
    fontSize: theme.typography.pxToRem(22),
    color: theme.customStyles.extraColors.introCategories,
  },
})

const MChipContainer = ({
  classes,
  children,
  divider,
  title,
  type,
  variant,
  style,
}) => {
  return (
    <div
      key="1"
      className={classnames(classes.chipContainer, {
        [classes.chipContainerTags]: type == 'tags',
        [classes.isVerticalChip]: variant == 'vertical' && type == 'tags',
        [classes.isVertical]: variant == 'vertical' && type == 'categories',
      })}
      style={style}
    >
      {typeof title == 'string' ? (
        <Typography key="2" variant="h2" className={classes.headline}>
          {title}
        </Typography>
      ) : (
        ''
      )}

      {type == 'tags' || !divider
        ? children
        : addDivider(children, classes.divider)}
    </div>
  )
}

MChipContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  divider: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.oneOf(['categories', 'tags']),
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
}

MChipContainer.defaultProps = {
  divider: false,
  type: 'categories',
  variant: 'horizontal',
}

export default withStyles(styles)(MChipContainer)
